// React-related imports
import { ChangeEvent, useCallback, useContext, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// External libraries
import { useQuery } from '@tanstack/react-query';
import { Header, Tabs, SlideOverFilters } from '@GxT-Team/gxt-components';

// Hooks
import { usePaginatedFilters } from '../../hooks/usePaginatedFilters';
import useModal from '../../hooks/useModal';

// Components
import { CatalogTable, CatalogUploadAlertMessage, UploadReportSlideOver } from './components';
import { Layout } from '../../components';
import { DeleteConfirmationDialogue } from '../../components/modals/DeleteConfirmationDialogue';
import { TableActionBar } from '../../components/TableActionBar';

// API services
import { useCatalog, useMappingSessionAPI } from '../../services/api';
import { getProjects, getProjectPlaceholders } from '../../services/api/projectSettings';

// Utility functions
import { getFilterFetcher, mapToFilterOptions, setFilterSearchability } from './util/helpers';
import defaultFilters from './util/defaultCatalogTabsFilters';

// Contexts
import { CatalogContext } from '../../contexts/CatalogContext';

// Types
import {
  CatalogFilterParams,
  CatalogSortParams,
  GetMappingSessionsFilterOptionsResponse,
  GetSourceFilterOptionsResponse,
  InferSortParams,
  MappingSessionFilterParams,
  MappingSessionSortParams,
} from '../../services/api/types';
import { DocumentTabs as TabsType, Option } from '../../types';
import { ButtonConfig } from '../../components/types';
import PlaceholderModal from '../../components/modals/PlaceholderModal';
import { Placeholder } from '../../services/api/types';
import { MappingType } from '../../services/api/types';

const defaultTemplateType: Option = {
  title: 'Automated Templates',
  value: 'Automated',
};

const projectDefaultOption: Option = {
  title: 'View All Projects',
  value: 'View All Projects',
};

const targetUploadDefaultOption: Option = {
  title: 'Upload',
  value: 'Upload',
};

const CatalogPage = () => {
  const { state } = useLocation();

  const [activeTab, setActiveTab] = useState(
    (state && (state.activeTab as TabsType)) || TabsType.TARGET,
  );
  const [templateType, setTemplateType] = useState(state?.templateType || defaultTemplateType);
  const [selectedRecords, setSelectedRecords] = useState<string[]>([]);
  const [selectedProject, setSelectedProject] = useState<string>('');
  const [existingPlaceholders, setExistingPlaceholders] = useState<Placeholder[]>();

  const [appliedFilters, setAppliedFilters] = useState(
    defaultFilters(activeTab, templateType.value),
  );
  const assignPlaceholderModal = useModal();
  const {
    paginatedFilteredData,
    filters,
    setFilters,
    setSearchByKey,
    appliedFiltersCount,
    setAppliedFiltersCount,
    handlePageChange,
    handlePerPageChange,
    handleColumnSort,
  } = usePaginatedFilters<
    CatalogFilterParams | MappingSessionFilterParams,
    InferSortParams<CatalogSortParams & MappingSessionSortParams>
  >(
    useMemo(
      () => ({
        filters: defaultFilters(activeTab, templateType.value),
        search: {
          query: '',
          columns: ['id', 'title'],
        },
        sort: { type: 'DESC', column: 'id' },
      }),
      [activeTab, templateType.value],
    ),
  );

  const sideOverFilters = useModal();
  const { deleteCatalog } = useCatalog();
  const { archiveSession } = useMappingSessionAPI();
  const isBulkDeleteDisabled = selectedRecords.length === 0;
  const slideOverReport = useModal();
  const navigate = useNavigate();
  const { catalogFilesUploadStatus } = useContext(CatalogContext);

  const showSourceDocuments = activeTab === TabsType.SOURCE;
  const showTargetDocuments = activeTab === TabsType.TARGET;

  // Tabs related to mapping sessions
  const showInProgressMappingSessions = activeTab === TabsType.IN_PROGRESS; // In-Progress tab
  const showCompletedMappingSessions = activeTab === TabsType.COMPLETED; // Completed tab

  const {
    show: showBulkDeleteModal,
    closeModal: closeBulkDeleteModal,
    openModal: openBulkDeleteModal,
  } = useModal();

  const fetchFilterOptions = async () => {
    try {
      const fetchFilterOptionsFn = getFilterFetcher(activeTab, templateType.value);

      const { data } = await fetchFilterOptionsFn();
      const isMappingSessionsFilters = 'ctdSection' in data && 'status' in data;

      if (isMappingSessionsFilters) {
        setFilterSearchability(data);
      }
      return data;
    } catch (error) {
      console.error('Error fetching filterOptions data:', error);
      throw error;
    }
  };

  const filtersQueryKey = useMemo(() => {
    if (
      (activeTab === TabsType.TARGET && templateType.value === 'Target') ||
      activeTab === TabsType.SOURCE
    )
      return 'CatalogFilterOptions';
    else return 'MappingSessionFilterOptions';
  }, [activeTab, templateType.value]);

  const filtersQuery = useQuery({
    queryFn: fetchFilterOptions,
    queryKey: [filtersQueryKey],
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const fetchProjectsOptions = async () => {
    try {
      const fetchProjectsOptionsFn = getProjects;

      const { data } = await fetchProjectsOptionsFn({});
      const projectOptions = data.data.map((project) => ({
        title: project.name,
        value: project.id,
      }));

      return projectOptions;
    } catch (error) {
      console.error('Error fetching projects Options data:', error);
      throw error;
    }
  };

  const fetchProjectPlaceholders = async (projectId: number): Promise<Placeholder[]> => {
    try {
      const fetchProjectsOptionsFn = getProjectPlaceholders;
      const { data } = await fetchProjectsOptionsFn(projectId);
      return data.placeholders;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const projectsQuery = useQuery({
    queryFn: fetchProjectsOptions,
    queryKey: ['projects'],
    enabled: activeTab === TabsType.IN_PROGRESS || activeTab === TabsType.COMPLETED,
  });

  const projectsOptions: Option[] = useMemo(() => {
    return (
      projectsQuery.data?.map((project) => ({
        title: project.title,
        value: project.value.toString(),
      })) || []
    );
  }, [projectsQuery.data]);

  const targetUploadOption: Option[] = [
    {
      title: 'Manual Template',
      value: 'manualTemplate',
    },
    // will use this option for external authoring
    // {
    //   title: 'External Authoring',
    //   value: 'externalAuthoring',
    // },
  ];

  const handlePlaceholderSelection = async (option: string) => {
    switch (option) {
      case 'Assign Placeholders':
        assignPlaceholderModal.openModal();
        break;
      case 'Edit Placeholders':
        if (selectedProject) {
          const placeholders: Placeholder[] = await fetchProjectPlaceholders(
            Number(selectedProject),
          );
          assignPlaceholderModal.openModal();
          setExistingPlaceholders(placeholders);
        }
        break;
      case 'Revert Placeholders':
        break;
    }
  };

  const tabs = useMemo(
    () => [
      {
        title: 'Templates',
        active: showTargetDocuments,
        onClickHandler: () => handleActiveTab(TabsType.TARGET),
      },
      {
        title: 'Sources',
        active: showSourceDocuments,
        onClickHandler: () => handleActiveTab(TabsType.SOURCE),
      },
      {
        title: 'In-Progress',
        active: showInProgressMappingSessions,
        onClickHandler: () => handleActiveTab(TabsType.IN_PROGRESS),
      },
      {
        title: 'Completed',
        active: showCompletedMappingSessions,
        onClickHandler: () => handleActiveTab(TabsType.COMPLETED),
      },
    ],
    [activeTab],
  );

  const preserveActiveTab = (tab: TabsType) => {
    navigate('.', {
      state: { ...state, activeTab: tab },
      replace: true,
    });
  };

  const handleActiveTab = (tab: TabsType) => {
    setSelectedRecords([]);
    setFilters(defaultFilters(tab, templateType.value));
    setActiveTab(tab);
    preserveActiveTab(tab);
    handleColumnSort('DESC', 'id');
    handlePageChange(1);
    setSelectedProject('');
    setSearchByKey('query', '');
    setAppliedFiltersCount(0);
    if (tab === TabsType.TARGET) setTemplateType(defaultTemplateType);
  };

  const handleSelectAll = useCallback((_records: { id: string }[], newSelection: string[]) => {
    setSelectedRecords(newSelection);
  }, []);

  const handleSelect = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelectedRecords((prev) => [...prev, event.target.value]);
    } else {
      setSelectedRecords((prev) => prev.filter((id) => id !== event.target.value));
    }
  };

  const handleBulkDelete = async () => {
    const records: number[] = selectedRecords.map((record) => Number(record));
    const isTargetOrExternal =
      activeTab === TabsType.TARGET &&
      (templateType.value === 'Target' || templateType.value === 'External');

    const isSource = activeTab === TabsType.SOURCE;

    const action = isTargetOrExternal || isSource ? deleteCatalog : archiveSession;

    await action.mutateAsync(records).finally(() => {
      closeBulkDeleteModal();
    });
    setSelectedRecords([]);
  };

  const handleUpload = () => {
    switch (activeTab) {
      case TabsType.SOURCE:
        navigate('/upload/source');
        break;
      case TabsType.TARGET:
        navigate('/catalog/new');
        break;
    }
  };

  const actionButtons: ButtonConfig[] = useMemo(() => {
    const templateDropdown: ButtonConfig = {
      type: 'select',
      variant: 'secondary',
      options: [
        {
          title: 'Automated Templates',
          value: 'Automated',
        },
        {
          title: 'Manual Templates',
          value: 'Target',
        },
        // will use this option for external authoring
        // {
        //   title: 'External Documents',
        //   value: 'External',
        // },
      ],
      optionValue: templateType,
      onSelect: (option) => {
        setFilters(defaultFilters(activeTab, option.value));
        setTemplateType(option);
      },
      title: 'Automated Templates',
      disabled: false,
      dataTestId: 'template-dropdown',
      styles: 'whitespace-nowrap',
      id: 'template-dropdown',
      defaultValue: templateType,
    };

    const externalFileTemplateUpload = (value: string) => {
      if (value === 'externalAuthoring') {
        navigate('/catalog/new', {
          state: { external: true },
        });
      } else {
        handleUpload();
      }
    };

    const projectDropdown: ButtonConfig = {
      type: 'select',
      variant: 'secondary',
      options: [projectDefaultOption, ...projectsOptions],
      onSelect: (option) => {
        setFilters((prev) => {
          return {
            ...prev,
            projectId: option.value === 'View All Projects' ? undefined : +option.value,
          };
        });
        setSelectedProject(option.value);
      },
      title: 'View All Projects',
      disabled: false,
      dataTestId: 'project-dropdown',
      styles: 'whitespace-nowrap',
      id: 'project-dropdown',
      defaultValue: projectDefaultOption,
      placeholder: selectedProject ? selectedProject : undefined,
    };

    const uploadDropdown: ButtonConfig = {
      type: 'select',
      variant: 'secondary',
      options: [...targetUploadOption],
      onSelect: (option) => {
        externalFileTemplateUpload(option.value);
      },
      title: 'Upload',
      disabled: ![TabsType.SOURCE, TabsType.TARGET].includes(activeTab),
      dataTestId: 'project-dropdown',
      styles: 'whitespace-nowrap',
      id: 'project-dropdown',
      defaultValue: targetUploadDefaultOption,
    };

    const placeHolderDropdown: ButtonConfig = {
      type: 'select',
      variant: 'secondary',
      options: [
        {
          title: 'Assign Placeholders',
          value: 'Assign Placeholders',
        },
        {
          title: 'Edit Placeholders',
          value: 'Edit Placeholders',
        },
        // TODO: will uncomment it when implemented
        // {
        //   title: 'Revert Placeholders',
        //   value: 'Revert Placeholders',
        // },
      ],
      onSelect: (option) => {
        handlePlaceholderSelection(option.value);
      },
      disabled: !selectedProject || selectedProject == 'View All Projects',
      dataTestId: 'placeholder-dropdown',
      styles: 'whitespace-nowrap',
      customStyles: {
        container: {
          height: '100%',
          position: 'relative',
        },
        button: { whiteSpace: 'nowrap', height: '100%' },
        itemsWrapper: {
          zIndex: 100,
          position: 'absolute',
          right: '0',
        },
      },
      id: 'placeholder-dropdown',
      defaultValue: {
        title: 'Placeholders',
        value: 'placeholders',
      },
    };

    const commonButtons: ButtonConfig[] = [
      {
        type: 'button',
        variant: 'secondary',
        onClick: sideOverFilters.openModal,
        title: `Filter (${appliedFiltersCount})`,
        iconType: 'filter-icon',
        disabled: filtersQuery.isLoading,
        dataTestId: 'filter-btn',
        styles: 'flex justify-between items-center gap-3 whitespace-nowrap',
        id: 'filter-btn',
      },
    ];

    if (activeTab === TabsType.TARGET) {
      commonButtons.unshift(templateDropdown);
    }

    const bulkDeleteButton: ButtonConfig = {
      type: 'button',
      variant: 'negative',
      onClick: openBulkDeleteModal,
      title: 'Bulk Delete',
      disabled: isBulkDeleteDisabled,
      id: 'bulk-delete-btn',
      dataTestId: 'bulk-delete-btn',
      styles: 'whitespace-nowrap ',
    };

    if (activeTab === TabsType.IN_PROGRESS) {
      return [...commonButtons, projectDropdown, placeHolderDropdown, bulkDeleteButton];
    } else if (activeTab === TabsType.COMPLETED) {
      return [...commonButtons, projectDropdown];
    } else if (activeTab === TabsType.TARGET) {
      return [...commonButtons, uploadDropdown, bulkDeleteButton];
    }

    return [
      ...commonButtons,
      {
        type: 'button',
        variant: 'secondary',
        onClick: handleUpload,
        title: 'Upload',
        disabled: ![TabsType.SOURCE, TabsType.TARGET].includes(activeTab),
        id: 'upload-btn',
        dataTestId: 'upload-btn',
        styles: 'whitespace-nowrap',
      },
      bulkDeleteButton,
    ];
  }, [activeTab, appliedFiltersCount, selectedRecords, openBulkDeleteModal, templateType]);

  const handleApplyFilters = () => {
    const defaultFiltersArray = defaultFilters(activeTab, templateType.value);

    const defaultMappingType = (defaultFiltersArray as MappingSessionFilterParams)?.mappingType
      ?.length
      ? (defaultFiltersArray as MappingSessionFilterParams).mappingType
      : '';

    const appliedMappingType = (appliedFilters as MappingSessionFilterParams)?.mappingType?.length
      ? (appliedFilters as MappingSessionFilterParams).mappingType
      : '';

    setFilters((prev) => {
      return {
        ...defaultFiltersArray,
        ...appliedFilters,
        ...(activeTab === TabsType.COMPLETED
          ? {
              mappingType: (appliedMappingType || defaultMappingType) as MappingType,
            }
          : {}),
        ...(prev && 'projectId' in prev
          ? {
              projectId: prev.projectId,
            }
          : {}),
      };
    });

    const totalSelectedItems = Object.values(appliedFilters || {}).reduce((sum: number, value) => {
      if (Array.isArray(value)) {
        return sum + value.length;
      }
      return sum;
    }, 0);

    setAppliedFiltersCount(totalSelectedItems);
    sideOverFilters.closeModal();
  };

  const handleResetFilters = () => {
    setAppliedFilters(defaultFilters(activeTab, templateType.value));
    setAppliedFiltersCount(0);
  };

  const handleCloseFilters = () => {
    sideOverFilters.closeModal();
    setAppliedFilters(filters);
  };
  const getFiltersData = () => {
    if (activeTab === TabsType.TARGET && templateType.value === 'Target') {
      const { ctdSections, regions, version, createdBy } =
        filtersQuery.data as Partial<GetSourceFilterOptionsResponse>;
      return {
        ctdSections,
        regions,
        version,
        createdBy,
      } as Partial<GetSourceFilterOptionsResponse>;
    } else if (activeTab === TabsType.SOURCE) {
      const { materials, processIds, dpImages, sites, createdBy } =
        filtersQuery.data as Partial<GetSourceFilterOptionsResponse>;
      return {
        materials,
        processIds,
        dpImages,
        sites,
        createdBy,
      } as Partial<GetSourceFilterOptionsResponse>;
    } else {
      const data = filtersQuery.data as Partial<GetMappingSessionsFilterOptionsResponse>;
      const { status, ctdSections, createdById, regions, version, projects } = data;
      const mappingTypeOptions = [
        {
          label: 'Manual',
          value: MappingType.AUTHORING,
        },
        {
          label: 'Automated',
          value: MappingType.AI_AUTHORING,
        },
      ];
      const mappingType = {
        label: 'Type',
        name: 'mappingType',
        options: mappingTypeOptions,
        isSearchAble: mappingTypeOptions.length > 5,
      };

      if (activeTab === TabsType.TARGET) {
        data.createdById && (data.createdById.label = 'Uploaded by');
        return {
          ctdSections,
          regions,
          version,
          createdById,
        } as Partial<GetMappingSessionsFilterOptionsResponse>;
      } else if (activeTab === TabsType.COMPLETED) {
        return {
          projects,
          mappingType,
          ctdSections,
          createdById,
        } as Partial<GetMappingSessionsFilterOptionsResponse>;
      } else {
        data.createdById && (data.createdById.label = 'Owner');
        return {
          mappingType,
          ctdSections,
          status,
          createdById,
        } as Partial<GetMappingSessionsFilterOptionsResponse>;
      }
    }
  };

  const filtersOptionsData = useMemo(() => {
    const filtersData:
      | Partial<GetSourceFilterOptionsResponse>
      | Partial<GetMappingSessionsFilterOptionsResponse>
      | undefined = filtersQuery.data && getFiltersData();
    return mapToFilterOptions(filtersData, (filters) => {
      setAppliedFilters(filters as any);
    });
  }, [filtersQuery.data, activeTab]);

  const { error, success, pending } = useMemo(
    () =>
      catalogFilesUploadStatus.reduce(
        (counts, { status }) => {
          counts[status] += 1;
          return counts;
        },
        { success: 0, error: 0, pending: 0 },
      ),
    [catalogFilesUploadStatus],
  );

  return (
    <Layout>
      <Layout.Header>
        <Header>
          <Header.Heading>Catalog</Header.Heading>
        </Header>
      </Layout.Header>
      <Layout.Body>
        <div className={`${pending > 0 && 'flex justify-between'} w-full items-center gap-3`}>
          <Tabs tabs={tabs} />
          <CatalogUploadAlertMessage
            totalFileCount={catalogFilesUploadStatus.length}
            onReportClick={slideOverReport.openModal}
            error={error}
            success={success}
            pending={pending}
          />
        </div>
        <div className="flex flex-col gap-[1rem] flex-1 w-full">
          <TableActionBar
            handleSearch={(term) => {
              setSearchByKey('query', term);
            }}
            buttons={actionButtons}
            searchTerm={paginatedFilteredData.search.query}
          />
          <CatalogTable
            templateType={templateType.value}
            activeTab={activeTab}
            paginatedFilteredData={paginatedFilteredData}
            handleColumnSort={handleColumnSort}
            handleSelectAll={handleSelectAll}
            handleSelect={handleSelect}
            handlePageChange={handlePageChange}
            handlePerPageChange={handlePerPageChange}
            selectedRecords={selectedRecords}
          />
        </div>
        <SlideOverFilters
          isOpen={sideOverFilters.show}
          onCloseHandler={handleCloseFilters}
          onApplyHandler={handleApplyFilters}
          onResetHandler={handleResetFilters}
          filtersOptions={filtersOptionsData}
          applyButtonLabel="Filter"
          closeButtonLabel="Close"
          resetButtonLabel="Clear Selection"
          title={`${activeTab === TabsType.COMPLETED ? 'Filter completed documents' : 'Filters'}`}
        />
        <DeleteConfirmationDialogue
          isOpen={showBulkDeleteModal}
          handleClose={closeBulkDeleteModal}
          handleDelete={handleBulkDelete}
        />
        <UploadReportSlideOver
          data={catalogFilesUploadStatus}
          isOpen={slideOverReport.show}
          onClose={slideOverReport.closeModal}
        />
        {selectedProject && assignPlaceholderModal.show && (
          <PlaceholderModal
            modalIsOpen={assignPlaceholderModal.show}
            closeModal={assignPlaceholderModal.closeModal}
            selectedProject={selectedProject}
            existingPlaceholders={existingPlaceholders?.length ? existingPlaceholders : undefined}
            setExistingPlaceholders={
              existingPlaceholders?.length ? setExistingPlaceholders : undefined
            }
          />
        )}
      </Layout.Body>
    </Layout>
  );
};

export default CatalogPage;
