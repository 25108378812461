import React, { FC, useMemo, useRef, useState } from 'react';
import { Button, SlideOver } from '@GxT-Team/gxt-components';
import EditCatalogTemplateForm from './EditCatalogTemplateForm';
import {
  CatalogTemplateFormProps,
  EditCatalogItemOption,
  EditCatalogTemplateProps,
  ActiveSlideOverType,
  SlideOverConfig,
} from './EditCatalogTemplate.types';
import { useCatalog, useMappingSessionAPI } from '../../../../services/api';
import { DocumentTabs, DocumentTabsWithoutExternal } from '../../../../types';
import useModal from '../../../../hooks/useModal';
import { CheckboxSelectionSlideOver } from '../../../../components/CheckboxSelectionSlideOver';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { getProjects } from '../../../../services/api/projectSettings';
import { InProgressEditValues, TargetEditValues } from '../CatalogTable/CatalogTable.types';
import { getAllCatalogItemsWithPagination } from '../../../settings/components/utils/apiHelpers';
import { ItemType } from '../../../../services/api/types';
import { perPageFilterOptions } from '../../../../constants/catalog';
import { handleInfiniteScroll } from '../../../../helpers/utils';

const EditCatalogTemplates: FC<EditCatalogTemplateProps> = ({
  isOpen,
  onClose,
  activeTab,
  defaultValues,
}) => {
  const formRef = useRef<{ submitForm: () => void }>(null);
  const [formData, setFormData] = useState<CatalogTemplateFormProps>({
    ...defaultValues,
    materials: defaultValues.materials || [],
    ctdSections: defaultValues.ctdSections || [],
    project: defaultValues.project || [],
    processIds: defaultValues.processIds || [],
    sites: defaultValues.sites || [],
    dpImages: defaultValues.dpImages || [],
  });

  const { editMappingSession } = useMappingSessionAPI();
  const { editCatalog } = useCatalog();

  const slideOver = useModal();
  const [activeSlideOver, setActiveSlideOver] = useState<ActiveSlideOverType | null>(null);

  const openSlideOver = (type: ActiveSlideOverType) => {
    setActiveSlideOver(type);
    slideOver.openModal();
  };

  const handleClose = () => {
    slideOver.closeModal();
    setActiveSlideOver(null);
  };

  const handleFormSubmit = () => {
    if (formRef.current) {
      formRef.current.submitForm();
    }
  };

  const handleSubmitData = async (_data: TargetEditValues) => {
    const data = {
      materials: formData?.materials?.map((material) => material.value) || [],
      processIds: formData?.processIds?.map((processId) => processId.value) || [],
      sites: formData?.sites?.map((site) => site.value) || [],
      dpImages: formData?.dpImages?.map((dpImage) => dpImage.value) || [],
      ctdSections: formData?.ctdSections?.map((ctdSection) => ctdSection.value) || [],
      version: formData?.version,
      documentNum: formData?.documentNum || '',
    };

    if (defaultValues.id) {
      await editCatalog.mutateAsync({ id: +defaultValues.id, data });
    }
    onClose();
  };

  const handleAutomatedSubmitData = async (data: TargetEditValues) => {
    const sessionData = {
      id: defaultValues?.id,
      title: data.templateName || '',
      version: data.version ?? 1,
      materials: formData?.materials?.map((region) => region.value) || [],
      ctdSections: formData?.ctdSections?.map((ctdSection) => ctdSection.value) || [],
    };

    await editMappingSession.mutateAsync(sessionData);
    onClose();
  };

  const handleInProgressSubmitData = async (data: InProgressEditValues) => {
    const sessionData = {
      id: defaultValues.id,
      title: data.title || '',
      projectId: formData?.project?.[0]?.value,
      ctdSections: formData?.ctdSections?.map((ctdSection) => ctdSection.value) || [],
    };

    await editMappingSession.mutateAsync(sessionData);
    onClose();
  };

  const editTabProperties: Record<
    DocumentTabsWithoutExternal,
    { heading: string; submitData: (data: any) => void }
  > = {
    [DocumentTabs.AUTOMATED]: {
      heading: 'Edit Automated Template Properties',
      submitData: handleAutomatedSubmitData,
    },
    [DocumentTabs.TARGET]: {
      heading: 'Edit Target Template Properties',
      submitData: handleSubmitData,
    },
    [DocumentTabs.SOURCE]: {
      heading: 'Edit Source Properties',
      submitData: handleSubmitData,
    },
    [DocumentTabs.IN_PROGRESS]: {
      heading: 'Edit In-Progress Document Properties',
      submitData: handleInProgressSubmitData,
    },
    [DocumentTabs.PRE_AUTHORED]: {
      heading: 'Edit Pre-Authored Document Properties',
      submitData: (_data: any) => {},
    },
    [DocumentTabs.COMPLETED]: {
      heading: 'Edit Completed Document Properties',
      submitData: (_data: any) => {},
    },
  };

  const { data: projectResponse } = useQuery({
    queryKey: ['catalog-edit-projects'],
    queryFn: () => getProjects({}),
    enabled: activeTab === DocumentTabs.IN_PROGRESS,
  });

  const {
    data: catalogItemsResponse,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    isLoading,
  } = useInfiniteQuery({
    queryKey: [activeSlideOver],
    queryFn: ({ pageParam = 1 }) =>
      getAllCatalogItemsWithPagination(
        activeSlideOver && activeSlideOver !== 'project' ? [activeSlideOver] : ItemType.Material,
        {
          page: pageParam,
          perPage: perPageFilterOptions,
        },
      ),
    getNextPageParam: (lastPage) => {
      if (lastPage.prevOffset * perPageFilterOptions > lastPage.total) {
        return undefined;
      }
      return lastPage?.prevOffset + 1;
    },
    enabled: activeSlideOver !== 'project' && activeSlideOver !== null,
    initialPageParam: 1,
  });

  const projectsData = useMemo(
    () =>
      (projectResponse?.data.data || []).map(({ id, name }) => ({
        value: id.toString(),
        label: name,
      })),
    [projectResponse],
  );

  const catalogItemsData = useMemo(() => {
    return (
      catalogItemsResponse?.pages?.reduce(
        (acc, page) => {
          page?.items.forEach(({ id, name, type }) => {
            if (!acc[type]) {
              acc[type] = [];
            }
            acc[type].push({ value: id.toString(), label: name });
          });
          return acc;
        },
        {} as Record<string, { value: string; label: string }[]>,
      ) || {}
    );
  }, [catalogItemsResponse]);

  const handleScroll = (event: React.UIEvent<HTMLElement>) => {
    handleInfiniteScroll(event, hasNextPage, isFetchingNextPage, fetchNextPage);
  };

  const slideOverConfigs: Record<ActiveSlideOverType, SlideOverConfig> = {
    project: {
      title: 'Project',
      options: projectsData,
      field: 'project',
    },
    [ItemType.Material]: {
      title: 'Materials',
      options: catalogItemsData[ItemType.Material] || [],
      field: 'materials',
      handleScroll,
      fetchingNextPage: isFetchingNextPage,
    },
    [ItemType.CTDSection]: {
      title: 'CTD Sections',
      options: catalogItemsData[ItemType.CTDSection] || [],
      field: 'ctdSections',
      handleScroll,
      fetchingNextPage: isFetchingNextPage,
    },
    [ItemType.Site]: {
      title: 'Sites',
      options: catalogItemsData[ItemType.Site] || [],
      field: 'sites',
      handleScroll,
      fetchingNextPage: isFetchingNextPage,
    },
    [ItemType.ProcessID]: {
      title: 'Process IDs',
      options: catalogItemsData[ItemType.ProcessID] || [],
      field: 'processIds',
      handleScroll,
      fetchingNextPage: isFetchingNextPage,
    },
    [ItemType.DPImage]: {
      title: 'DP Images',
      options: catalogItemsData[ItemType.DPImage] || [],
      field: 'dpImages',
      handleScroll,
      fetchingNextPage: isFetchingNextPage,
    },
    [ItemType.Region]: {
      title: 'Region',
      options: catalogItemsData[ItemType.Region] || [],
      field: 'regions',
      handleScroll,
      fetchingNextPage: isFetchingNextPage,
    },
  };

  return (
    <SlideOver isOpen={isOpen} onClose={onClose} mountElementId="styled-wrapper" width="28rem">
      <SlideOver.Header className="flex p-6 flex-col items-start gap-2 self-stretch bg-primary-50 text-system-50">
        {/* This type will be updated when the edit functionality is required. */}
        {editTabProperties[activeTab as DocumentTabsWithoutExternal].heading}
      </SlideOver.Header>
      <EditCatalogTemplateForm
        ref={formRef}
        handleSubmitData={editTabProperties[activeTab as DocumentTabsWithoutExternal].submitData}
        activeTab={activeTab}
        defaultValues={formData}
        openProjectSlideOver={() => openSlideOver('project')}
        openMaterialsSlideOver={() => openSlideOver(ItemType.Material)}
        openCTDSectionsSlideOver={() => openSlideOver(ItemType.CTDSection)}
        openSitesSlideOver={() => openSlideOver(ItemType.Site)}
        openProcessIdsSlideOver={() => openSlideOver(ItemType.ProcessID)}
        openDPImagesSlideOver={() => openSlideOver(ItemType.DPImage)}
        setFormData={setFormData}
      />
      {activeSlideOver && slideOver.show && (
        <CheckboxSelectionSlideOver
          isOpen={slideOver.show}
          handleClose={handleClose}
          onSave={(value) => {
            setFormData({
              ...formData,
              [slideOverConfigs[activeSlideOver].field]: value,
            });
            handleClose();
          }}
          options={slideOverConfigs[activeSlideOver].options}
          selectedOptions={
            (formData[
              slideOverConfigs[activeSlideOver].field as keyof CatalogTemplateFormProps
            ] as EditCatalogItemOption[]) || []
          }
          title={slideOverConfigs[activeSlideOver].title}
          {...(slideOverConfigs[activeSlideOver].handleScroll && {
            handleScroll: slideOverConfigs[activeSlideOver].handleScroll,
          })}
          {...(slideOverConfigs[activeSlideOver].fetchingNextPage && {
            fetchingNextPage: slideOverConfigs[activeSlideOver].fetchingNextPage,
          })}
          isLoading={isLoading}
          multiple={slideOverConfigs[activeSlideOver].field !== 'ctdSections'}
        />
      )}
      <SlideOver.Footer className="flex flex-col items-center self-stretch">
        <div className="flex gap-4 justify-end items-center flex-grow flex-shrink flex-basis-0 w-full">
          <Button variant="primary" onClick={handleFormSubmit}>
            Save
          </Button>
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
        </div>
      </SlideOver.Footer>
    </SlideOver>
  );
};

export default EditCatalogTemplates;
